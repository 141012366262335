import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import logoImage from '@public/images/4cut-ranking/4cut-ranking-main-logo.webp';
import TrendingRegion from '@components/4cut-ranking/TrendingRegion';
import MainPhotoCardVs from '@features/4cut-ranking-main/components/MainPhotoCardVs';
import MainGaugeBar from '@features/4cut-ranking-main/components/MainGaugeBar';
import MainCtaButtons from '@features/4cut-ranking-main/components/MainCtaButtons';
import useGetHotRegion from '@src/apis/querys/region-queries/useGetHotRegion';

const MainPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: clamp(550px, auto, 720px);
  padding-bottom: 32px;
  max-height: 720px;
  margin: auto 0;
`;

const Logo = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;

  > img {
    object-fit: contain;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
`;

const MainContent = () => {
  const { region } = useGetHotRegion();

  return (
    <MainPageWrap id="main">
      <Logo>
        <Image src={logoImage} alt="지역 얼짱 찾기 - 포컷랭킹" />
      </Logo>
      <TrendingRegion region={region} />
      <MainPhotoCardVs />
      <MainGaugeBar />
      <MainCtaButtons />
    </MainPageWrap>
  );
};

export default MainContent;
