import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PushIcon from '@icons/header/push.svg';
import SettingIcon from '@icons/header/setting.svg';
import useAppRouter from '@hooks/useAppRouter';
import useDeviceStore from '@hooks/useDeviceStore';
import RedDot from '@components/common/RedDot';
import { Assets } from '@src/svg';

const RedDotWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const PushAndSettingIconButtons = (): React.ReactElement => {
  const router = useAppRouter();

  const [state] = useDeviceStore();
  const [isApp, setApp] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setApp(!!state?.deviceId);
    }
  }, [state?.deviceId]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {router.pathname === '/ranking' && (
        <Assets.SearchIcon
          onClick={() => router.push('/ranking/search')}
          width={24}
          height={24}
        />
      )}
      {isApp && (
        <RedDotWrap>
          <PushIcon onClick={() => router.push('/push-history')} />
          <RedDot />
        </RedDotWrap>
      )}
      <SettingIcon onClick={() => router.push('/setting')} />
    </div>
  );
};

export default PushAndSettingIconButtons;
